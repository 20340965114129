import { QuestionIcon } from "@chakra-ui/icons"
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Textarea,
  Tooltip,
} from "@chakra-ui/react"
import { useEffect } from "react"

export enum InputFieldType {
  text,
  textarea,
  email,
  password,
}

interface Props {
  label: string
  name: string
  type: InputFieldType
  value: string
  isRequired?: boolean
  placeholder?: string
  minLength?: number
  maxLength?: number
  pattern?: string
  displayErrorIfInvalid?: boolean
  errorText?: string
  tooltip?: string
  onChangeHandler: (value: string) => void
  onIsValidChangeHandler?: (isValid: boolean) => void
}

const InputField = ({
  onChangeHandler,
  onIsValidChangeHandler,
  ...props
}: Props) => {
  const isValid =
    (props.type !== InputFieldType.email ||
      /^[^@]+@[^@]+\.[^@]+$/.test(props.value)) &&
    (!props.isRequired || props.value.length > 0) &&
    props.value.length >= (props.minLength ?? 0) &&
    props.value.length <= (props.maxLength ?? 9999999) &&
    (!props.pattern || RegExp(props.pattern!).test(props.value))

  useEffect(() => {
    onIsValidChangeHandler && onIsValidChangeHandler(isValid)
  }, [isValid, onIsValidChangeHandler, props.value])

  return (
    <>
      <FormControl
        isInvalid={props.displayErrorIfInvalid && !isValid}
        isRequired={props.isRequired}
      >
        <FormLabel htmlFor={props.name}>
          {props.label}{" "}
          {props.tooltip && (
            <Tooltip label={props.tooltip}>
              <QuestionIcon />
            </Tooltip>
          )}
        </FormLabel>
        {props.type === InputFieldType.textarea ? (
          <Textarea
            id={props.name}
            placeholder={props.placeholder}
            value={props.value}
            resize="vertical"
            onChange={(event) => onChangeHandler(event.target.value)}
          />
        ) : (
          <Input
            id={props.name}
            type={InputFieldType[props.type]}
            placeholder={props.placeholder}
            value={props.value}
            onChange={(event) => onChangeHandler(event.target.value)}
          />
        )}
        {props.displayErrorIfInvalid && !isValid && (
          <FormErrorMessage>
            {props.errorText ?? "Feltet er ugyldigt"}
          </FormErrorMessage>
        )}
      </FormControl>
    </>
  )
}

export default InputField
