import { Alert, AlertIcon, Box, Stack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { MaterialAuthor, MaterialResponse } from '../../api/generated'
import { useAppDispatch, useAppSelector } from '../../hooks/hooks'
import {
  CreateMaterialAsync,
  selectCreateMaterialState,
  selectMaterialById,
  selectUpdateMaterialState,
  selectUploadMaterialPictureState,
  selectUploadMaterialState,
  UpdateMaterialAsync,
  UploadMaterialFileAsync,
  UploadMaterialPictureAsync,
} from '../../redux/materialsSlice'
import CustomModal from '../CustomModal'
import CustomCheckbox from '../form/inputs/CustomCheckbox'
import CustomFileInput from '../form/inputs/CustomFileInput'
import InputField, { InputFieldType } from '../form/inputs/InputField'
import InputSelect from '../form/inputs/InputSelect'
import InputSubmitButton from '../form/inputs/InputSubmitButton'

interface Props {
  isOpen: boolean
  onClose: () => void
  title: string
  content: string
  materialId: number | null
}
const CreateUpdateMaterialModal = (props: Props) => {
  const [title, setTitle] = React.useState<string | null>(null)
  const [titleEn, setTitleEn] = React.useState<string | null>(null)
  const [description, setDescription] = React.useState<string | null>(null)
  const [descriptionEn, setDescriptionEn] = React.useState<string | null>()
  const [author, setAuthor] = React.useState(MaterialAuthor.CAMILLA)
  // const [freeOfCharge, setFreeOfCharge] = React.useState(false)
  const [chosenFile, setChosenFile] = React.useState<Blob | null>(null)
  const [chosenFileEn, setChosenFileEn] = React.useState<Blob | null>(null)
  const [chosenPicture, setChosenPicture] = React.useState<Blob | null>(null)

  const [errorMode, setErrorMode] = useState(false)

  const createMatState = useAppSelector(selectCreateMaterialState)
  const updateMatState = useAppSelector(selectUpdateMaterialState)
  const uploadMatState = useAppSelector(selectUploadMaterialState)
  const uploadPicState = useAppSelector(selectUploadMaterialPictureState)
  const materialToUpdate = useAppSelector(selectMaterialById(props.materialId))
  const dispatch = useAppDispatch()
  const isValid = true // nothing required so far

  useEffect(() => {
    if (props.isOpen) return
    setTitle(null)
    setTitleEn(null)
    setDescription(null)
    setDescriptionEn(null)
    setAuthor(MaterialAuthor.CAMILLA)
    // setFreeOfCharge(false)
    setChosenFile(null)
    setChosenFileEn(null)
    setChosenPicture(null)

    setErrorMode(false)
  }, [props.isOpen])

  useEffect(() => {
    if (!props.isOpen || !materialToUpdate) return
    setTitle(materialToUpdate.title)
    setTitleEn(materialToUpdate.titleEn)
    setDescription(materialToUpdate.description)
    setDescriptionEn(materialToUpdate.descriptionEn)
    setAuthor(materialToUpdate.author)
    // setFreeOfCharge(materialToUpdate.freeOfCharge)
  }, [materialToUpdate, props.isOpen])

  const uploadFiles = async (materialId: number): Promise<boolean> => {
    var file1Response = !!chosenFile
      ? await dispatch(
          UploadMaterialFileAsync({
            id: materialId,
            file: chosenFile!,
            en: false,
          })
        )
      : undefined

    var file2Response = !!chosenFileEn
      ? await dispatch(
          UploadMaterialFileAsync({
            id: materialId,
            file: chosenFileEn!,
            en: true,
          })
        )
      : undefined

    var pictureResponse = !!chosenPicture
      ? await dispatch(
          UploadMaterialPictureAsync({
            id: materialId,
            file: chosenPicture!,
          })
        )
      : undefined

    return (
      (!file1Response || file1Response.meta.requestStatus === 'fulfilled') &&
      (!file2Response || file2Response.meta.requestStatus === 'fulfilled') &&
      (!pictureResponse || pictureResponse.meta.requestStatus === 'fulfilled')
    )
  }

  const handleFormSubmit = async () => {
    if (!isValid) {
      setErrorMode(true)
      return
    }

    setErrorMode(false)
    if (!!materialToUpdate) {
      dispatch(
        UpdateMaterialAsync({
          id: materialToUpdate.id,
          body: {
            title,
            titleEn,
            description,
            descriptionEn,
            author,
            // freeOfCharge,
          },
        })
      ).then(async (value) => {
        if (value.meta.requestStatus === 'fulfilled') {
          var uploadsCompleted = await uploadFiles(materialToUpdate.id)
          uploadsCompleted === true && props.onClose()
        }
      })
    } else
      dispatch(
        CreateMaterialAsync({
          title,
          titleEn,
          description,
          descriptionEn,
          author,
          // freeOfCharge,
        })
      ).then(async (value) => {
        if (value.meta.requestStatus === 'fulfilled') {
          var materialId = (value.payload as MaterialResponse).id
          var uploadsCompleted = await uploadFiles(materialId)
          uploadsCompleted === true && props.onClose()
        }
      })
  }

  const SubmitButton = (
    <InputSubmitButton
      disabled={errorMode && !isValid}
      submitting={
        createMatState.status === 'loading' ||
        updateMatState.status === 'loading' ||
        uploadMatState.status === 'loading' ||
        uploadPicState.status === 'loading'
      }
      title="Udfør"
      onClickHandler={handleFormSubmit}
    />
  )

  return (
    <CustomModal
      isOpen={props.isOpen}
      onClose={props.onClose}
      title={props.title}
      content={props.content}
      submitButton={SubmitButton}
    >
      <Box>
        <form onSubmit={(e) => e.preventDefault()}>
          <Stack spacing={3}>
            <InputField
              label="Titel"
              name="materialTitle"
              type={InputFieldType.text}
              onChangeHandler={setTitle}
              value={title || ''}
              placeholder="Meditation.."
            />

            <InputField
              label="Titel (engelsk)"
              name="materialTitleEn"
              type={InputFieldType.text}
              onChangeHandler={setTitleEn}
              value={titleEn || ''}
              placeholder="Mediation.."
            />

            <InputField
              label="Beskrivelse"
              name="materialDescription"
              type={InputFieldType.textarea}
              onChangeHandler={setDescription}
              value={description || ''}
              placeholder="Denne øvelse handler om at..."
            />

            <InputField
              label="Beskrivelse (engelsk)"
              name="materialDescriptionEm"
              type={InputFieldType.textarea}
              onChangeHandler={setDescriptionEn}
              value={descriptionEn || ''}
              placeholder="This exercise is about..."
            />

            <InputSelect
              label="Indtalt af"
              name="materialAuthor"
              onChangeHandler={(value) => {
                const authorKey = Object.keys(MaterialAuthor).find(
                  (key) =>
                    MaterialAuthor[key as keyof typeof MaterialAuthor] === value
                )
                setAuthor(
                  MaterialAuthor[authorKey as keyof typeof MaterialAuthor]
                )
              }}
              value={author}
              options={Object.values(MaterialAuthor).map((val) => ({
                value: val,
                label: val,
              }))}
            />

            {/* <CustomCheckbox
              label="Gratis"
              name="freeOfCharge"
              onChangeHandler={setFreeOfCharge}
              isChecked={freeOfCharge}
              tooltip="Vælg hvis materialet er gratis. Materialet vil kunne tilgås i appen uden at være logget ind og have købt adgang."
            /> */}

            <CustomFileInput
              label="Lyd/video-fil"
              existingFileName={materialToUpdate?.fileName ?? undefined}
              existingFileUrl={materialToUpdate?.filePath ?? undefined}
              name="materialFile"
              tooltip="Vælg en fil som skal tilhøre materialet. Filen kan være kan kun være en lyd-fil i formatet .m4a"
              onChangeHandler={setChosenFile}
            />

            <CustomFileInput
              label="Lyd/video-fil (engelsk)"
              existingFileName={materialToUpdate?.fileNameEn ?? undefined}
              existingFileUrl={materialToUpdate?.filePathEn ?? undefined}
              name="materialFile"
              tooltip="Vælg en fil som skal tilhøre materialet. Filen kan være kan kun være en lyd-fil i formatet .m4a"
              onChangeHandler={setChosenFileEn}
            />

            <CustomFileInput
              label="Billede-fil"
              existingFileName={
                materialToUpdate?.picture?.split('/').pop() ?? undefined
              }
              existingFileUrl={materialToUpdate?.picture ?? undefined}
              name="materialPicture"
              tooltip="Vælg et billede som skal vises på siden for den pågældende øvelse. Filen skal være af typen png, jpg eller tif. Billedet skal være i landscape-mode"
              onChangeHandler={setChosenPicture}
              isDisabled={!title && !titleEn}
            />

            {[
              createMatState.status,
              updateMatState.status,
              uploadMatState.status,
              uploadPicState.status,
            ].includes('failed') && (
              <Alert status="error">
                <AlertIcon />
                {createMatState.errorMessage ||
                  updateMatState.errorMessage ||
                  uploadMatState.errorMessage ||
                  uploadPicState.errorMessage}
              </Alert>
            )}
          </Stack>
        </form>
      </Box>
    </CustomModal>
  )
}

export default CreateUpdateMaterialModal
